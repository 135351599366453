import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import CategoryAccordion from "./CategoryAccordion"

const QuestionsSection = () => {
  const data = useStaticQuery(graphql`
    query FAQQuery {
      allContentfulKategoriaFaq {
        nodes {
          category
          categoryDescription
          order
          faq {
            answer {
              raw
            }
            question
            contentful_id
          }
        }
      }
    }
  `)

  const categories = data.allContentfulKategoriaFaq.nodes.sort(
    (a, b) => a.order - b.order
  )

  return (
    <Grid>
      {categories.map((category, i) => {
        if (!!category.faq.length) {
          return (
            <CategoryAccordion {...category} key={category.contentful_id} />
          )
        } else {
          return null
        }
      })}
    </Grid>
  )
}

export default QuestionsSection
