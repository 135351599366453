import React from "react"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"
import { Grid } from "@mui/material"
import Accordion from "components/Accordion/Accordion"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Hr from "components/Hr/Hr"
import { options } from "constants/richTextOptions"

const CategoryAccordion = ({
  category,
  categoryDescription: description,
  faq: qa,
  ...rest
}) => {
  const accordionData = qa.map(({ answer, question, contentful_id }) => ({
    question,
    answer: <>{renderRichText(answer, options)}</>,
    id: contentful_id,
  }))

  return (
    <Grid {...rest}>
      <Hr mb={4} mt={6} />
      <Font42>{category}</Font42>
      {description && <Font18>{description}</Font18>}
      <Grid mt={5}>
        <Accordion accordionData={accordionData} />
      </Grid>
    </Grid>
  )
}

export default CategoryAccordion
