import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import QuestionsSection from "containers/FAQ/QuestionsSection"

const FAQ = () => {
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "Dla Opiekunki",
      link: "/dla-opiekunki/etapy-rekrutacji-opiekunki-osob-starszych",
    },
    "Pytania i odpowiedzi",
  ]

  const { faqMain } = useStaticQuery(
    graphql`
      query {
        faqMain: file(name: { eq: "faq-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "#e5f5f7"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(faqMain)
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Pytania i odpowiedzi">
      <Header
        title={"Pytania i odpowiedzi"}
        titleDescription={"Odpowiedzi na najczęściej zadawane pytania"}
        mainImage={mainImage}
        mainImageAlt={"Pytania i odpowiedzi"}
      />
      <QuestionsSection />
    </Layout>
  )
}

export default FAQ
